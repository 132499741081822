<template>
  <section class="depot">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <h2 class="main-tab__title active">Производство</h2>

      <button
        type="button"
        class="btn btn-gold"
        data-bs-toggle="modal"
        data-bs-target="#create-application-free-task-modal"
      >
        Создать
      </button>
    </div>

    <PlanTableWrapper />
  </section>

  <EditManufactureModal
    id="create-application-free-task-modal"
    title="Создать задачу"
    :manufactureOptionsList="taskState.manufactureOptionsList"
    :productOptionsList="taskState.productOptionsList"
    :stockOptionsList="taskState.stockOptionsList"
    :measurementOptionsList="taskState.measurementOptionsList"
    :apiRequest="createTask"
    :validation="validation"
    @redirectToTask="redirectToTask"
  />
</template>

<script>
import useTask from "@/mixins/useTask";

import PlanTableWrapper from "@/components/plan/PlanTableWrapper.vue";
import EditManufactureModal from "@/components/formation/EditManufactureModal.vue";

export default {
  name: "plan-page",
  components: { PlanTableWrapper, EditManufactureModal },
  setup() {
    const { taskState, redirectToTask, createTask, validation } = useTask();

    return { taskState, redirectToTask, createTask, validation };
  },
};
</script>
